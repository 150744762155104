import Vue from 'vue'

// 通过Vue.observable创建一个可响应的对象
export const store = Vue.observable({
	gameno: 1,
	gameType: 1
})

// 定义 mutations, 修改属性
export const mutations = {
	setGameno(no) {
		store.gameno = no
	},
	setGameType(type) {
		store.gameType = type
	}
}
