function addZero(i) {
	return i < 10 ? "0" + i : i + "";
}

export function countDown(time) {
	var h = parseInt(time / (60 * 60) % 24);
	var m = parseInt(time / 60 % 60);
	var s = parseInt(time % 60);
	h = addZero(h);
	m = addZero(m);
	s = addZero(s);
	return m + ':' + s
	if (time <= 0) {
		return false;
	}
}

export function formatTime(time) {
	var m = parseInt(time / 60 % 60);
	var s = parseInt(time % 60);
	return [m, s]
	if (time <= 0) {
		return false;
	}
}

export function formatTimeRank(time) {
	var h = parseInt(time / (60 * 60) % 24);
	var m = parseInt(time / 60 % 60);
	var s = parseInt(time % 60);
	h = addZero(h);
	m = addZero(m);
	s = addZero(s);
	return [m, s]
	if (time <= 0) {
		return false;
	}
}
export function isPad() {
	var ua = navigator.userAgent;
	if (/sm-p/i.test(ua)) {
		return true;
	} else {
		return false;
	}
}
export function getQueryVariable(variable) {
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split("=");
		if (pair[0] == variable) {
			return pair[1];
		}
	}
	return false;
}
